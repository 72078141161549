import { Container, ListItem, Spacer, UnorderedList } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Policy from "./Policy";
import Documents from "./Documents";
import Popup from "../../components/Popup";
import { useSelector } from "react-redux";
import moment from "moment";
import store from "../../redux/Store";
import { getUserData, setSessionOut } from "../../redux/slice/userSlice";

const Home = () => {
  const [popup, setPopup] = useState(false);
  const { user_id } = useSelector((state) => state.user);

  useEffect(() => {
    store.dispatch(getUserData(user_id));
  }, []);

  const { policy_list } = useSelector((state) => state.policy);
  const { document_list } = useSelector((state) => state.document);
  const day1 = new Date();
  const day2 = new Date();
  const day7 = new Date();

  day1.setDate(day1.getDate() + 1);
  day2.setDate(day2.getDate() + 2);
  day7.setDate(day7.getDate() + 7);

  const policydata = policy_list.filter((item) => {
    return (
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day1).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day2).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day7).format("YYYY-MM-DD")
    );
  });
  const document = document_list.filter((item) => {
    return (
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day1).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day2).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day7).format("YYYY-MM-DD")
    );
  });

  const show = localStorage.getItem("popup");
  useEffect(() => {
    show == "show" &&
      (policydata.length > 0 || document.length > 0) &&
      setPopup(true);
  }, [policydata, document]);

  useEffect(() => {
    store.dispatch(setSessionOut(true));
  }, []);

  return (
    <Container maxW={"full"} px={[1, 4, 6, 10]} py={[2, 5]}>
      <Policy />
      <Spacer h={7} />
      <Documents />
      <Popup
        open={popup ? true : false}
        setOpen={() => {
          localStorage.removeItem("popup");
          setPopup(false);
        }}
        title={"Alert"}
        mess={
          <UnorderedList>
            {policydata.map((v, i) => (
              <ListItem key={i}>
                {`your ${v.insurance_type} insurance with ${
                  v.policy_company
                } is about to expire in ${
                  (moment(v.expiry_date).format("YYYY-MM-DD") ==
                    moment(day1).format("YYYY-MM-DD") &&
                    "1 day") ||
                  (moment(v.expiry_date).format("YYYY-MM-DD") ==
                    moment(day2).format("YYYY-MM-DD") &&
                    "2 days") ||
                  (moment(v.expiry_date).format("YYYY-MM-DD") ==
                    moment(day7).format("YYYY-MM-DD") &&
                    "7 days")
                }
                `}
              </ListItem>
            ))}
            {document.map((v, i) => (
              <ListItem key={i}>
                {`your document ${v.doc_type} is due to expire in ${
                  (moment(v.expiry_date).format("YYYY-MM-DD") ==
                    moment(day1).format("YYYY-MM-DD") &&
                    "1 day") ||
                  (moment(v.expiry_date).format("YYYY-MM-DD") ==
                    moment(day2).format("YYYY-MM-DD") &&
                    "2 days") ||
                  (moment(v.expiry_date).format("YYYY-MM-DD") ==
                    moment(day7).format("YYYY-MM-DD") &&
                    "7 days")
                }
                `}
              </ListItem>
            ))}
          </UnorderedList>
        }
      />
    </Container>
  );
};

export default Home;
{
  /* <li 
                key={i}
                fontSize={["sm", "md", "xl", "lg"]}
                fontWeight={"normal"}
              ></li> */
}
// useEffect(() => {
//   // store
//   //   .dispatch(getPolicy_list())
//   //   .unwrap()
//   //   .then((res) => {
//   //     if (res.success) {
//   //       const policy = res?.message?.filter((item) => {
//   //         return (
//   //           moment(item.expiry_date).format("YYYY-MM-DD") ==
//   //             moment(day1).format("YYYY-MM-DD") ||
//   //           moment(item.expiry_date).format("YYYY-MM-DD") ==
//   //             moment(day2).format("YYYY-MM-DD") ||
//   //           moment(item.expiry_date).format("YYYY-MM-DD") ==
//   //             moment(day7).format("YYYY-MM-DD")
//   //         );
//   //       });
//   //       policy?.length > 0 && localStorage.setItem("popup", "show");
//   //       setPolicy(policy);
//   //     } else {
//   //       localStorage.removeItem("popup");
//   //     }
//   //   });
//   // store
//   //   .dispatch(getDocument_list())
//   //   .unwrap()
//   //   .then((res) => {
//   //     if (res.success) {
//   //       const doc = res?.message?.filter((item) => {
//   //         return (
//   //           moment(item.expiry_date).format("YYYY-MM-DD") ==
//   //             moment(day1).format("YYYY-MM-DD") ||
//   //           moment(item.expiry_date).format("YYYY-MM-DD") ==
//   //             moment(day2).format("YYYY-MM-DD") ||
//   //           moment(item.expiry_date).format("YYYY-MM-DD") ==
//   //             moment(day7).format("YYYY-MM-DD")
//   //         );
//   //       });
//   //       doc?.length > 0 && localStorage.setItem("popup", "show");
//   //       setDoc(doc);
//   //     } else {
//   //       localStorage.removeItem("popup");
//   //     }
//   //   });
// }, [show == null]);
