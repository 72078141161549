import {
  Center,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";

const SelectImage = ({
  onChange,
  label,
  url,
  filename,
  mt,
  type,
  inputRef,
  doctype,
}) => {
  const inputOpenFileRef = React.createRef();
  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };
  return (
    <FormControl flex={1} mt={mt}>
      <Text fontSize={"md"} fontWeight={"normal"} my={2}>
        {label}
      </Text>
      {type === "application/pdf" || type === "pdf"
        ? url && (
            <iframe
              src={url}
              allowFullScreen={true}
              loading="lazy"
              style={{
                backgroundColor: "#fff",
                height: "220px",
                marginBottom: "10px",
                width: "100%",
              }}
            />
          )
        : url && (
            <Image
              src={url}
              h={"220px"}
              w={"full"}
              borderRadius={8}
              objectFit={"contain"}
              overflow={"hidden"}
              mb={2}
              bg={"gray.100"}
            />
          )}
      <HStack
        ref={inputRef}
        border={"1px solid #EBEAEA"}
        borderRadius={5}
        h={"10"}
        alignItems={"center"}
        onClick={showOpenFileDlg}
        overflow={"hidden"}
      >
        <Text
          bg={"#EBEAEA"}
          h={10}
          m={0}
          paddingInline={8}
          textAlign={"center"}
          alignItems={"center"}
          pt={2}
          fontSize={"md"}
          fontWeight={"medium"}
        >
          Browse
        </Text>
        <Text>{filename || "Choose file"}</Text>
        <Input
          ref={inputOpenFileRef}
          type="file"
          onChange={onChange}
          display={"none"}
          accept={doctype || "image/png, image/jpg, image/jpeg ,.pdf"}
        />
      </HStack>
    </FormControl>
  );
};

export default SelectImage;
