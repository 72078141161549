import {
  Container,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { decryptData, downloadImage } from "../utils/utils";
import CustomButton1 from "./CustomButton1";
import { FiDownload } from "react-icons/fi";
const ImageModal = ({ url = "", isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        url = "";
      }}
    >
      <ModalOverlay />
      <ModalContent
        maxW={"container.xl"}
        h={"full"}
        w={"full"}
        padding={0}
        margin={0}
        position={"fixed"}
      >
        <ModalCloseButton zIndex={100} bg={"#99999988"} />
        {url?.split(".").pop().trim() == "pdf" || (
          <ModalHeader>
            <Flex justify={"end"} marginRight={10}>
              <CustomButton1
                title={"Download"}
                onClick={() => downloadImage(url)}
                icon={<FiDownload />}
              />
            </Flex>
          </ModalHeader>
        )}
        <ModalBody position={"relative"} overflow={"scroll"}>
          {url?.split(".").pop().trim() == "pdf" ? (
            <iframe
              src={url}
              style={{
                width: "100%",
                height: "100%",
              }}
              frameborder="1"
              loading="eager"
              onLoadStart={() => setLoading(true)}
              onLoadedData={() => setLoading(false)}
            ></iframe>
          ) : (
            // <Container h={"full"}>

            <Image
              src={url}
              w={"full"}
              h={"full"}
              // minH={"max-content"}
              objectFit={"contain"}
            />

            // </Container>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
