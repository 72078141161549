import { Box, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { doc_status_action } from "../../utils/apis";
import { toast } from "react-toastify";
import CustomPopup from "../../components/CustomPopup";
import CustomInput from "../../components/CustomInput";
import { encryptData } from "../../utils/utils";
import store from "../../redux/Store";
import { getUserData } from "../../redux/slice/userSlice";

const DocPass = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [d_data, setD_data] = useState({});
  const [d_status, setD_status] = useState(0);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user_data } = useSelector((state) => state.user);
  const doc_status = (val) => {
    if (!d_data.password && val === 1) {
      setD_data({
        ...d_data,
        passwordval: true,
        passwordmess: "password is required",
      });
      return;
    }
    const body = new FormData();
    body.append("action", "update_docpwd");
    body.append("id", user.userid);
    body.append("status", val);
    body.append("password", encryptData(d_data.password)); //encrypted password
    doc_status_action(body).then((v) => {
      if (v.success) {
        store.dispatch(getUserData(user.userid));
        setD_data({ ...d_data, password: "" });
        onClose();
        toast.success(v.message);
      } else {
        toast.error(v.message);
      }
    });
  };

  return (
    <>
      <Stack>
        <Text>Would you like to password protect the document?</Text>
        <Flex gap={7}>
          <Flex
            alignItems={"center"}
            gap={2}
            cursor={"pointer"}
            onClick={() => {
              Number(user_data.docpwd_status) == 1 || setD_status(1);
              Number(user_data.docpwd_status) == 1 || onOpen();
            }}
          >
            <Box
              h={4}
              w={4}
              borderRadius={"full"}
              bg={
                Number(user_data.docpwd_status) === 1 ? "#FFBF00" : "gray.400"
              }
            ></Box>
            <Text fontWeight={"semibold"}>Yes</Text>
          </Flex>
          <Flex
            alignItems={"center"}
            gap={2}
            cursor={"pointer"}
            onClick={() => {
              Number(user_data.docpwd_status) == 2 ||  Number(user_data.docpwd_status) === 0 || setD_status(2);
              Number(user_data.docpwd_status) == 2 ||  Number(user_data.docpwd_status) === 0 || onOpen();
            }}
          >
            <Box
              h={4}
              w={4}
              borderRadius={"full"}
              bg={
                Number(user_data.docpwd_status) === 2 ||  Number(user_data.docpwd_status) === 0 ? "#FFBF00" : "gray.400"
              }
            ></Box>
            <Text fontWeight={"semibold"}>No</Text>
          </Flex>
        </Flex>
      </Stack>
      {/* )} */}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={
          d_status == 2 ? "Previous Document Password" : "Protect Document"
        }
        mess={
          <>
            <CustomInput
              label={"Password"}
              placeholder={"Enter password"}
              value={d_data.password}
              onChange={(e) => {
                setD_data({
                  ...d_data,
                  password: e.target.value,
                  passwordmess: "",
                  passwordval: false,
                });
              }}
              error={d_data.passwordmess}
              type={"password"}
            />
          </>
        }
        b_name={"Submit"}
        b_color={"#FFBF00"}
        onClick={() => {
          doc_status(d_status);
        }}
      />
    </>
  );
};

export default DocPass;
