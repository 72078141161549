import { Card, Center, Spinner } from "@chakra-ui/react";
import React from "react";

const Loader = () => {
  return (
    <Center py={"10"} bg={"gray.100"} mt={4} borderRadius={7}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#FFBF00"
        size="xl"
      />
    </Center>
  );
};

export default Loader;
