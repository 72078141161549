import { configureStore } from "@reduxjs/toolkit";
import policySlice from "./slice/policySlice";
import DocumentSlice from "./slice/DocumentSlice";
import userSlice from "./slice/userSlice";
import notificationSlice from "./slice/notificationSlice";
import claimSlice from "./slice/claimSlice";

const store = configureStore({
  reducer: {
    policy: policySlice,
    document: DocumentSlice,
    user: userSlice,
    notifications: notificationSlice,
    claims: claimSlice,
  },
});

export default store;
