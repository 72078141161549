import {
  Center,
  Container,
  Flex,
  Heading,
  Input,
  Link,
  Select,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomButton1 from "../../components/CustomButton1";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../redux/Store";
import {
  getPolicy_list,
  searchPolicy_list,
} from "../../redux/slice/policySlice";
import TableView from "../../components/TableView";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import ImageModal from "../../components/ImageModal";
import CustomPopup from "../../components/CustomPopup";
import { addQute, addUserPolicy } from "../../utils/apis";
import {  decryptData, downloadImage, infoMess } from "../../utils/utils";
import CustomModal from "../../components/CustomModal";
import CustomInput from "../../components/CustomInput";
import { getUserData } from "../../redux/slice/userSlice";
import Loader from "../../components/Loader";
import moment from "moment";
import ImgComp from "../../components/ImgComp";
import Img1 from "../../images/Icon1.jpg";
import Img2 from "../../images/Icon2.jpg";
import Img3 from "../../images/Icon3.jpg";
import Img4 from "../../images/other4.jpg";

const Policy = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const navigate = useNavigate();
  const location = useLocation();
  const { user_data } = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState("");
  const [initialValue, setInitialValue] = useState(1);
  

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPOpen,
    onOpen: onPOpen,
    onClose: onPClose,
  } = useDisclosure();
  const {
    isOpen: isMOpen,
    onOpen: onMOpen,
    onClose: onMClose,
  } = useDisclosure();

  const { policy_list, p_loading, s_loading, search_policy_list } = useSelector(
    (state) => state.policy
  );
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [quote, setQuote] = useState({});
  const [name, setName] = useState("all");

  useEffect(() => {
    localStorage.getItem("user");
    store.dispatch(getPolicy_list(user.userid));
    store
      .dispatch(getUserData(user.userid))
      .then((v) => setQuote(v.payload.message));
  }, []);
  const delete_policy = () => {
    onPClose();
    const body = new FormData();
    body.append("action", "delete");
    body.append("userid", ID);
    body.append("id", id);
    addUserPolicy(body, setLoading).then(
      (v) => v.success && store.dispatch(getPolicy_list(ID))
    );
  };

  const sendQuote = () => {
    if (!quote.Name || !quote.Email || !quote.mobile || !quote.mess) {
      infoMess("all fields are required");
      return;
    }
    const body = new FormData();
    body.append("userid", ID);
    body.append("instype", type);
    body.append("name", quote.Name);
    body.append("email", quote.Email);
    body.append("contact", quote.mobile);
    body.append("id", id);
    body.append("message", quote.mess);
    body.append("policy", "existing");
    addQute(body, setLoading).then((v) => {
      v.success && onMClose();
      v.success && setQuote({ ...quote, mess: "" });
    });
  };
  const todayDate = new Date();
  todayDate.setDate(todayDate.getDate() - 1);
  const data = policy_list.filter((item) => {
    return (
      moment(item.expiry_date).format("YYYY-MM-DD  HH:mm:ss") >=
      moment(todayDate).format("YYYY-MM-DD  HH:mm:ss")
    );
  });
  const data1 = search_policy_list.filter((item) => {
    return (
      moment(item.expiry_date).format("YYYY-MM-DD  HH:mm:ss") >=
      moment(todayDate).format("YYYY-MM-DD  HH:mm:ss")
    );
  });

  function daysRemaining(date) {
    var eventdate = moment(date);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }
  const healthCount = data.filter(
    (item) => item.insurance_type.toLowerCase() === "health"
  ).length;
  const motorCount = data.filter(
    (item) => item.insurance_type.toLowerCase() === "motor"
  ).length;
  const lifeCount = data.filter(
    (item) => item.insurance_type.toLowerCase() === "life"
  ).length;
  const otherCount = data.filter(
    (item) =>
      item.insurance_type.toLowerCase() !== "health" &&
      item.insurance_type.toLowerCase() !== "motor" &&
      item.insurance_type.toLowerCase() !== "life"
  ).length;
  const data2 = name == "all" ? data : data1;

  return (
    <Container maxW={"full"}>
      <Flex align={"center"} justify={"space-between"} mb={5}>
        <Heading
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#0f0d66"}
        >
          Existing Policies
        </Heading>
        <CustomButton1
          title={"Add a policy"}
          onClick={() => navigate("/add-policy")}
        />
      </Flex>
      {data.length == 0 || (
        <Flex
          gap={2}
          flexDirection={["column", "column", "row"]}
          justifyContent={"space-between"}
        >
          <Flex gap={2} alignItems={"center"} alignSelf={"start"}>
            <Text>Show</Text>
            <Select
              W={"44"}
              backgroundColor={"#fff"}
              size={"sm"}
              borderRadius={5}
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Select>
            <Text>entries</Text>
          </Flex>
          <Flex gap={2} alignItems={"center"}>
            <Text>Search:</Text>
            <Input
              placeholder="Search policy"
              w={"xs"}
              size="md"
              backgroundColor={"#fff"}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Flex>
        </Flex>
      )}
      <Flex overflowX={"auto"} display={"flex"} justify={["start", "center"]}>
        {data.length == 0 || (
          <Center gap={3} mb={3}>
            <CustomButton1
              title={"All"}
              bg={name === "all" ? "#FFBF00" : "#fff"}
              t_color={name === "all" ? "#fff" : "#000"}
              loading={name === "all" && s_loading}
              onClick={() => {
                store.dispatch(getPolicy_list(user.userid));
                setName("all");
              }}
            />
            <CustomButton1
              title={`Health (${healthCount})`}
              bg={name === "health" ? "#FFBF00" : "#fff"}
              t_color={name === "health" ? "#fff" : "#000"}
              loading={name === "health" && s_loading}
              onClick={() => {
                store.dispatch(
                  searchPolicy_list({ type: "health", id: user.userid })
                );
                setName("health");
              }}
            />
            <CustomButton1
              title={`Motor (${motorCount})`}
              bg={name === "motor" ? "#FFBF00" : "#fff"}
              t_color={name === "motor" ? "#fff" : "#000"}
              loading={name === "motor" && s_loading}
              onClick={() => {
                store.dispatch(
                  searchPolicy_list({ type: "motor", id: user.userid })
                );
                setName("motor");
              }}
            />
            <CustomButton1
              title={`Life (${lifeCount})`}
              bg={name === "life" ? "#FFBF00" : "#fff"}
              t_color={name === "life" ? "#fff" : "#000"}
              loading={name === "life" && s_loading}
              onClick={() => {
                store.dispatch(
                  searchPolicy_list({ type: "life", id: user.userid })
                );
                setName("life");
              }}
            />
            <CustomButton1
              title={`Others (${otherCount})`}
              bg={name === "others" ? "#FFBF00" : "#fff"}
              t_color={name === "others" ? "#fff" : "#000"}
              loading={name === "others" && s_loading}
              onClick={() => {
                store.dispatch(
                  searchPolicy_list({ type: "others", id: user.userid })
                );
                setName("others");
              }}
            />
          </Center>
        )}
      </Flex>
      <>
        {p_loading && policy_list.length == 0 ? (
          <Loader />
        ) : data.length == 0 ? (
          <>
            <Text
              fontSize={["14px", "16px", "18px"]}
              color={"#000"}
              fontWeight={"semibold"}
              marginTop={2}
              textTransform={"capitalize"}
            >
              Hi {user_data?.Name}!
            </Text>
            <Text
              fontSize={["12px", "14px", "16px"]}
              color={"#000"}
              fontWeight={"normal"}
              marginTop={2}
            >
              Let's add your first insurance
            </Text>
            <Flex flexWrap={"wrap"} display={"flex"} flexDirection={"row"}>
              <ImgComp
                img={Img1}
                title={"Motor Insurance"}
                onClick={() => navigate("/add-policy")}
              />
              <ImgComp
                img={Img3}
                title={"Health Insurance"}
                onClick={() => navigate("/add-policy")}
              />
              <ImgComp
                img={Img2}
                title={"Life Insurance"}
                onClick={() => navigate("/add-policy")}
              />
              <ImgComp
                img={Img4}
                title={"Other Insurance"}
                onClick={() => navigate("/add-policy")}
              />
            </Flex>
          </>
        ) : (
          <>
            <TableView
              headData={[
                "Name",
                "Company",
                "Insurance",
                "Plan/Registration",
                "Policy no.",
                "Expiry Date",
                "Action",
                "Quote",
              ]}
              body={data2
                .filter((v) => {
                  return (
                    v.name.toLowerCase().includes(search.toLowerCase()) ||
                    v.policy_company
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    v.policy_number
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    v.insurance_type
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    v.policy_plan.toLowerCase().includes(search.toLowerCase())
                  );
                })
                .slice(initialValue - 1, limit * page)
                .map((v, i) => (
                  <Tr key={i}>
                    <Td textAlign={"center"}>{v.name}</Td>
                    <Td textAlign={"center"}>{v.policy_company}</Td>
                    <Td textAlign={"center"}>{v.insurance_type}</Td>
                    <Td textAlign={"center"}>{v.policy_plan}</Td>
                    <Td textAlign={"center"}>{v.policy_number}</Td>
                    <Td textAlign={"center"}>
                      <Text
                        p={1}
                        fontWeight={"medium"}
                        borderRadius={3}
                        color={
                          daysRemaining(v.expiry_date) < 7
                            ? "#fff"
                            : daysRemaining(v.expiry_date) < 15
                            ? "#fff"
                            : daysRemaining(v.expiry_date) < 30
                            ? "#fff"
                            : "#000"
                        }
                        bg={
                          daysRemaining(v.expiry_date) < 7
                            ? "#f00"
                            : daysRemaining(v.expiry_date) < 15
                            ? "#FFCC00"
                            : daysRemaining(v.expiry_date) < 30
                            ? "#A9A9A9"
                            : "#fff"
                        }
                      >
                        {moment(v.expiry_date).format("DD/MM/YYYY")}
                        {/* {v.expiry_date} */}
                      </Text>
                    </Td>
                    <Td textAlign={"center"}>
                      {
                        <Flex align={"center"} gap={2} justify={"center"}>
                          {v.policy_file.length != 0 && (
                            <FiEye
                              size={20}
                              cursor={"pointer"}
                              onClick={() => {
                                setId(i);
                                onOpen();
                              }}
                            />
                          )}
                          <BiEditAlt
                            size={20}
                            cursor={"pointer"}
                            onClick={() =>
                              navigate("/edit-policy", { state: v })
                            }
                          />
                          {/* {v.policy_file.length != 0 && (
                            <ShareOptions
                              icon
                              url={decryptData(v?.policy_file)}
                            />
                          )} */}

                          <FiTrash2
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setId(v.id);
                              onPOpen();
                            }}
                          />
                          {v.policy_file.split(".").pop().trim() === "pdf" ? (
                            <Link
                              href={decryptData(v.policy_file)}
                              download
                              isExternal
                            >
                              {v.policy_file.length != 0 && (
                                <FaFileDownload size={20} cursor={"pointer"} />
                              )}
                            </Link>
                          ) : (
                            v.policy_file.length != 0 && (
                              <FaFileDownload
                                size={20}
                                cursor={"pointer"}
                                onClick={() =>
                                  downloadImage(decryptData(v.policy_file))
                                }
                              />
                            )
                          )}
                        </Flex>
                      }
                    </Td>
                    <Td textAlign={"center"}>
                      {daysRemaining(v.expiry_date) < 30 && (
                        <CustomButton1
                          title={"Get A Quote"}
                          onClick={() => {
                            setId(v.id);
                            setType(v.insurance_type);
                            onMOpen();
                          }}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
            />
            {data2?.filter((v) => {
              return (
                v.name.toLowerCase().includes(search.toLowerCase()) ||
                v.policy_company.toLowerCase().includes(search.toLowerCase()) ||
                v.policy_number.toLowerCase().includes(search.toLowerCase()) ||
                v.insurance_type.toLowerCase().includes(search.toLowerCase()) ||
                v.policy_plan.toLowerCase().includes(search.toLowerCase())
              );
            }).length == 0 && (
              <Text
                p={2}
                py={5}
                textAlign={"center"}
                fontSize={"lg"}
                fontWeight={"600"}
                color={"#000"}
              >
                No Policies Found
              </Text>
            )}
          </>
        )}
      </>
      <ImageModal
        isOpen={isOpen}
        onClose={onClose}
        url={decryptData(data[id]?.policy_file)}
      />
      <CustomPopup
        isOpen={isPOpen}
        onClick={() => delete_policy()}
        onClose={onPClose}
        b_name={"Delete"}
        title={"Delete"}
        mess={"Are you sure? you want to delete policy"}
      />
      <CustomModal
        isOpen={isMOpen}
        onClose={onMClose}
        body={
          <Stack>
            <Text textAlign={"center"} fontSize={"lg"} fontWeight={"medium"}>
              Get a Quote
            </Text>
            <CustomInput
              label={"Name*"}
              value={quote.Name}
              onChange={(e) => setQuote({ ...quote, Name: e.target.value })}
              placeholder={"Enter Name"}
            />
            <CustomInput
              label={"Email*"}
              value={quote.Email}
              onChange={(e) => setQuote({ ...quote, Email: e.target.value })}
              placeholder={"Enter Email"}
            />
            <CustomInput
              label={"Mobile*"}
              value={quote.mobile}
              onChange={(e) => setQuote({ ...quote, mobile: e.target.value })}
              placeholder={"Enter Mobile Number"}
            />
            <CustomInput
              label={"Quote Message*"}
              value={quote.mess}
              onChange={(e) => setQuote({ ...quote, mess: e.target.value })}
              placeholder={"Enter Message"}
              area
            />
            <Center mt={4}>
              <CustomButton1
                title={"Submit"}
                loading={loading}
                onClick={sendQuote}
              />
            </Center>
          </Stack>
        }
      />
      {data.length == 0 || (
        <Flex
          gap={2}
          flexDirection={["column", "row"]}
          justifyContent={"space-between"}
          mt={2}
        >
          <Text>{`Showing ${initialValue} to ${
            limit * page >= data2.length ? data2?.length : page * limit
          } of ${data2?.length} entries`}</Text>
          <Flex gap={2}>
            <Text
              cursor={page < 2 ? "not-allowed" : "pointer"}
              color={page < 2 ? "gray" : "#000"}
              fontSize={"lg"}
              fontWeight={"500"}
              onClick={() => {
                page > 1 && setPage(page - 1);
                page > 1 &&
                  setInitialValue(Number(initialValue) - Number(limit));
              }}
            >
              Previous
            </Text>
            <Text fontSize={"lg"} fontWeight={"500"}>
              {page}
            </Text>
            <Text
              cursor={limit * page >= data2.length ? "not-allowed" : "pointer"}
              color={limit * page >= data2.length ? "gray" : "#000"}
              fontSize={"lg"}
              fontWeight={"500"}
              onClick={() => {
                limit * page >= data2.length || setPage(page + 1);
                limit * page >= data2.length ||
                  setInitialValue(Number(initialValue) + Number(limit));
              }}
            >
              Next
            </Text>
          </Flex>
        </Flex>
      )}
    </Container>
  );
};

export default Policy;
