import {
  Center,
  Container,
  Flex,
  HStack,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomInput from "../../components/CustomInput";
import { useSelector } from "react-redux";
import CustomButton1 from "../../components/CustomButton1";
import {  errorMess, infoMess, successMess } from "../../utils/utils";
import { base_url, otp, profile_action } from "../../utils/apis";
import store from "../../redux/Store";
import { getUserData } from "../../redux/slice/userSlice";
import CustomPopup from "../../components/CustomPopup";
import { BiEditAlt } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from "react-toastify";

const MobileNumber = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user_data } = useSelector((state) => state.user);
  const [u_data, setU_data] = useState(user_data);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState("");
  const [del_mob, setDel_mob] = useState("");
  const [del_act, setDel_act] = useState("");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const [mobile, setMobile] = useState({
    id: "",
    id1: "",
    id2: "",
    show_otp: false,
    show_otp1: false,
    show_otp2: false,
    otp: "",
    otp1: "",
    otp2: "",
  });
  const [email, setEmail] = useState({
    eid: "",
    eshow_otp: false,
    eotp: "",
  });

  const get_otp = async (no, load) => {
    if (no?.length < 10) {
      setMobile(infoMess("Invalid mobile number"));
      return;
    }

    otp(no, no, setLoading, load).then((v) => {
      if (v.success) {
        successMess(v.message);
        load == 1 && setMobile({ ...mobile, id: v.id, show_otp: true });
        load == 2 && setMobile({ ...mobile, id1: v.id, show_otp1: true });
        load == 3 && setMobile({ ...mobile, id2: v.id, show_otp2: true });
      } else {
        errorMess(v.message);
      }
    });
  };
  const get_otp1 = async (load) => {
    try {
      setLoading(load);
      const body = new FormData();
      body.append("contact", user_data.Email);
      body.append("mobile", user_data.Email);
      body.append("type", "login");

      const responce = await fetch(base_url + "otp-request.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();
      setLoading("");

      if (res.success) {
        successMess(res.message);
        setEmail({ ...email, eid: res.id, eshow_otp: true });
      } else {
        errorMess(res.message);
      }
    } catch (error) {
      setLoading("");
    }
  };

  const add_mobile = (mo, id, otp, act) => {
    if (mo.length != 10) {
      infoMess("Invalid mobile number");
      return;
    }
    if (!otp || !id) {
      infoMess("OTP is required");
      return;
    }

    const body = new FormData();
    body.append("userid", ID);
    {
      act == "m" && body.append("mobile", mo);
    }
    {
      act == "m1" && body.append("mobile1", mo);
    }
    {
      act == "m2" && body.append("mobile2", mo);
    }
    body.append("id", id);
    body.append("otp", otp);
    body.append("action", "updatemobile");
    profile_action(body, setLoading, act).then((v) => {
      store.dispatch(getUserData(ID));
      console.log(v);
      if (v.success) {
        successMess(v.message);
        setEdit(false);
        setAdd(false);
        setMobile({
          ...mobile,
          id: "",
          id1: "",
          id2: "",
          show_otp: false,
          show_otp1: false,
          show_otp2: false,
          otp: "",
          otp1: "",
          otp2: "",
        });
      } else {
        errorMess(v.message);
      }
    });
  };
  const add_mobile1 = (mo, act) => {
    if (mo.length != 10) {
      infoMess("Invalid mobile number");
      return;
    }
    if (!email.eotp || !email.eid) {
      infoMess("Email OTP is required");
      return;
    }
    if (!mobile.otp || !mobile.id) {
      infoMess("Email OTP is required");
      return;
    }
    const body = new FormData();
    body.append("userid", ID);
    body.append("mobile", mo);
    body.append("eid", email.eid);
    body.append("eotp", email.eotp);
    body.append("id", mobile.id);
    body.append("otp", mobile.otp);
    body.append("action", "updatemobile");
    profile_action(body, setLoading, act).then((v) => {
      store.dispatch(getUserData(user.userid));
      if (v.success) {
        successMess(v.message);
        setEdit(false);
        setAdd(false);
        setMobile({
          ...mobile,
          id: "",
          id1: "",
          id2: "",
          show_otp: false,
          show_otp1: false,
          show_otp2: false,
          otp: "",
          otp1: "",
          otp2: "",
        });
        setEmail({
          ...email,
          eid: "",
          eshow_otp: false,
          eotp: "",
        });
      } else {
        errorMess(v.message);
      }
    });
  };

  const del_mobile = () => {
    onClose();
    const body = new FormData();
    body.append("userid", ID);
    {
      del_act == "del1" && body.append("mobile1", del_mob);
    }
    {
      del_act == "del2" && body.append("mobile2", del_mob);
    }
    body.append("action", "deletemobile");
    profile_action(body, setLoading, del_act).then((v) => {
      store
        .dispatch(getUserData(user.userid))
        .then((v) => setU_data(v.payload.message));
    });
  };
  return (
    <>
      <Container
        maxW={"container.md"}
        display={["block", "flex", "flex", "flex", "flex"]}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={4}
      >
        <Text
          mb={0}
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#0f0d66"}
        >
          Mobile Number
        </Text>
        {user.type == "Primary" && (
          <Flex gap={5}>
            {(user_data?.mobile?.length !== 0 &&
              user_data?.mobile1?.length !== 0 &&
              user_data?.mobile2?.length !== 0) || (
              <CustomButton1
                title={add ? "Cancel" : "Add"}
                icon={add ? <IoClose size={20} /> : <IoMdAddCircle size={20} />}
                onClick={() => setAdd(!add)}
                bg={add ? "#727b82" : "#FFBF00"}
              />
            )}
            <CustomButton1
              title={edit ? "Cancel" : "Edit"}
              icon={edit ? <IoClose size={20} /> : <BiEditAlt size={20} />}
              bg={edit ? "#727b82" : "#FFBF00"}
              onClick={() => {
                setU_data(user_data);
                setEdit(!edit);
                setMobile({
                  ...mobile,
                  id: "",
                  id1: "",
                  id2: "",
                  show_otp: false,
                  show_otp1: false,
                  show_otp2: false,
                  otp: "",
                  otp1: "",
                  otp2: "",
                });
                setEmail({
                  ...email,
                  eid: "",
                  eshow_otp: false,
                  eotp: "",
                });
              }}
            />
          </Flex>
        )}
      </Container>
      <Container
        w={"full"}
        maxW={"md"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {user_data?.mobile && (
          <>
            <CustomInput
              textonly={!edit}
              label={"Mobile"}
              value={edit ? u_data.mobile : user_data?.mobile}
              onChange={(e) => {
                e.target.value.length < 11 &&
                  setU_data({
                    ...u_data,
                    mobile: e.target.value.replace(/\D/g, ""),
                  });
              }}
              rightButton={
                edit &&
                u_data?.mobile !== user_data?.mobile && (
                  <CustomButton1
                    title={"Get OTP"}
                    mt={1}
                    onClick={() => {
                      get_otp(u_data?.mobile, 1);
                      get_otp1(1);
                    }}
                    loading={loading == 1 ? true : false}
                  />
                )
              }
            />

            {mobile?.show_otp && edit && (
              <>
                <Text
                  mb={0}
                  fontSize={["md", "lg"]}
                  fontWeight={"medium"}
                  color={"#0f0d66"}
                  mt={2}
                >
                  Mobile Number OTP
                </Text>
                <HStack justify={"center"} mt={3}>
                  <PinInput
                    placeholder="-"
                    autoFocus
                    value={mobile.otp}
                    onChange={(v) => setMobile({ ...mobile, otp: v })}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </>
            )}
            {email?.eshow_otp && edit && (
              <>
                <Text
                  mb={0}
                  fontSize={["md", "lg"]}
                  fontWeight={"medium"}
                  color={"#0f0d66"}
                  mt={2}
                >
                  Email OTP
                </Text>
                <HStack justify={"center"} mt={3}>
                  <PinInput
                    placeholder="-"
                    autoFocus
                    value={email.eotp}
                    onChange={(v) => setEmail({ ...email, eotp: v })}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </>
            )}
            {mobile?.show_otp && edit && (
              <Center mt={2}>
                <CustomButton1
                  title={"Update"}
                  mt={1}
                  onClick={() => add_mobile1(u_data?.mobile, "m")}
                  loading={loading == "m" ? true : false}
                />
              </Center>
            )}
          </>
        )}
        {user_data?.mobile1 && (
          <>
            <Flex maxW={"full"} gap={4}>
              <CustomInput
                textonly={!edit}
                label={"Mobile1"}
                value={edit ? u_data.mobile1 : user_data?.mobile1}
                onChange={(e) => {
                  e.target.value.length < 11 &&
                    setU_data({
                      ...u_data,
                      mobile1: e.target.value.replace(/\D/g, ""),
                    });
                }}
                rightButton={
                  edit &&
                  u_data?.mobile1 !== user_data?.mobile1 && (
                    <CustomButton1
                      title={"Get OTP"}
                      mt={1}
                      onClick={() => get_otp(u_data?.mobile1, 2)}
                      loading={loading == 2 ? true : false}
                    />
                  )
                }
              />
              {user.type == "Primary" && (
                <Stack alignSelf={"end"} mb={1.5}>
                  <CustomButton1
                    title={"Delete"}
                    bg={"red"}
                    loading={loading == "del1" ? true : false}
                    onClick={() => {
                      setDel_mob(user_data?.mobile1);
                      setDel_act("del1");
                      onOpen();
                    }}
                  />
                </Stack>
              )}
            </Flex>
            {mobile?.show_otp1 && edit && (
              <HStack justify={"center"} mt={3}>
                <PinInput
                  placeholder="-"
                  autoFocus
                  value={mobile.otp1}
                  onChange={(v) => setMobile({ ...mobile, otp1: v })}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            )}
            {mobile?.show_otp1 && edit && (
              <Center mt={2}>
                <CustomButton1
                  title={"Submit"}
                  mt={1}
                  onClick={() =>
                    add_mobile(u_data?.mobile1, mobile.id1, mobile.otp1, "m1")
                  }
                  loading={loading == "m1" ? true : false}
                />
              </Center>
            )}
          </>
        )}
        {user_data?.mobile2 && (
          <>
            <Flex maxW={"full"} gap={4}>
              <CustomInput
                textonly={!edit}
                label={"Mobile2"}
                value={edit ? u_data.mobile2 : user_data?.mobile2}
                onChange={(e) => {
                  e.target.value.length < 11 &&
                    setU_data({
                      ...u_data,
                      mobile2: e.target.value.replace(/\D/g, ""),
                    });
                }}
                rightButton={
                  edit &&
                  u_data?.mobile2 !== user_data?.mobile2 && (
                    <CustomButton1
                      title={"Get OTP"}
                      mt={1}
                      onClick={() => get_otp(u_data?.mobile2, 3)}
                      loading={loading == 3 ? true : false}
                    />
                  )
                }
              />
              <Stack alignSelf={"end"} mb={1.5}>
                <CustomButton1
                  title={"Delete"}
                  bg={"red"}
                  loading={loading == "del2" ? true : false}
                  onClick={() => {
                    setDel_mob(user_data?.mobile2);
                    setDel_act("del2");
                    onOpen();
                  }}
                />
              </Stack>
            </Flex>
            {mobile?.show_otp2 && edit && (
              <HStack justify={"center"} mt={3}>
                <PinInput
                  placeholder="-"
                  autoFocus
                  value={mobile.otp2}
                  onChange={(v) => setMobile({ ...mobile, otp2: v })}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            )}
            {mobile?.show_otp2 && edit && (
              <Center mt={2}>
                <CustomButton1
                  title={"Submit"}
                  mt={1}
                  onClick={() =>
                    add_mobile(u_data?.mobile2, mobile.id2, mobile.otp2, "m2")
                  }
                  loading={loading == "m2" ? true : false}
                />
              </Center>
            )}
          </>
        )}
        {add && (
          <Stack>
            {user_data?.mobile1.length == 0 && (
              <>
                <CustomInput
                  label={"Mobile1"}
                  value={u_data?.mobile1}
                  placeholder={"Enter Mobile Number"}
                  onChange={(e) => {
                    e.target.value.length < 11 &&
                      setU_data({
                        ...u_data,
                        mobile1: e.target.value.replace(/\D/g, ""),
                      });
                  }}
                  rightButton={
                    <CustomButton1
                      title={"Get OTP"}
                      mt={1}
                      onClick={() => get_otp(u_data?.mobile1, 2)}
                      loading={loading == 2 ? true : false}
                    />
                  }
                />
                {mobile?.show_otp1 && (
                  <HStack justify={"center"} mt={3}>
                    <PinInput
                      placeholder="-"
                      autoFocus
                      value={mobile.otp1}
                      onChange={(v) => setMobile({ ...mobile, otp1: v })}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                )}
                {mobile?.show_otp1 && (
                  <Center mt={2}>
                    <CustomButton1
                      title={"Submit"}
                      mt={1}
                      onClick={() =>
                        add_mobile(
                          u_data?.mobile1,
                          mobile.id1,
                          mobile.otp1,
                          "m1"
                        )
                      }
                      loading={loading == "m1" ? true : false}
                    />
                  </Center>
                )}
              </>
            )}
            {user_data?.mobile2.length == 0 && user_data.mobile1 != 0 && (
              <>
                <CustomInput
                  label={"Mobile2"}
                  value={u_data?.mobile2}
                  placeholder={"Enter Mobile Number"}
                  onChange={(e) => {
                    e.target.value.length < 11 &&
                      setU_data({
                        ...u_data,
                        mobile2: e.target.value.replace(/\D/g, ""),
                      });
                  }}
                  rightButton={
                    <CustomButton1
                      title={"Get OTP"}
                      mt={1}
                      onClick={() => get_otp(u_data?.mobile2, 3)}
                      loading={loading == 3 ? true : false}
                    />
                  }
                />
                {mobile?.show_otp2 && (
                  <HStack justify={"center"} mt={3}>
                    <PinInput
                      placeholder="-"
                      autoFocus
                      value={mobile.otp2}
                      onChange={(v) => setMobile({ ...mobile, otp2: v })}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                )}
                {mobile?.show_otp2 && (
                  <Center mt={2}>
                    <CustomButton1
                      title={"Submit"}
                      mt={1}
                      onClick={() =>
                        add_mobile(
                          u_data?.mobile2,
                          mobile.id2,
                          mobile.otp2,
                          "m2"
                        )
                      }
                      loading={loading == "m2" ? true : false}
                    />
                  </Center>
                )}
              </>
            )}
          </Stack>
        )}
      </Container>
      <CustomPopup
        isOpen={isOpen}
        onClick={() => del_mobile()}
        onClose={onClose}
        b_name={"Delete"}
        title={"Delete"}
        mess={"Are you sure? you want to delete mobile number."}
      />
    </>
  );
};

export default MobileNumber;
