import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomButton1 from "../../components/CustomButton1";
import { useNavigate } from "react-router-dom";
import BreadCrumName from "../../components/BreadCrumName";
import SelectImage from "../../components/SelectImage";
import CustomInput from "../../components/CustomInput";
import { addUserDocument, doc_status_action } from "../../utils/apis";
import {  decryptData, encryptData, infoMess } from "../../utils/utils";
import Select from "react-select";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomPopup from "../../components/CustomPopup";
import { toast } from "react-toastify";
import { encode, decode, btoa, Base64 } from "js-base64";
import { BiArrowBack } from "react-icons/bi";

const Adddocument = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const [d_status, setD_status] = useState("");
  const [d_data, setD_data] = useState({});
  const [loading, setLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user_data } = useSelector((state) => state.user);
  const [value, setValue] = useState(1);
  const [path, setPath] = useState("");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  console.log(user_data);
  // const SECRET_KEY = "sanjana_@22/01";
  // const encryptData = CryptoJS.AES.encrypt(
  //   JSON.stringify("shubham carpenter"),
  //   SECRET_KEY
  // ).toString();

  // const decrypted = CryptoJS.algo.AES.createDecryptor(SECRET_KEY, {
  //   iv: "6b94344135b92d7412d3679fce12bd52",
  // });

  // console.log(
  //   "decrypt data ==",
  //   decrypted.process("2wZHxuzklufvyf3Pl/UCy1vuqHWX4x74y2ISBiP3mDI=")
  //   // encryptData,
  //   // CryptoJS.AES.decrypt(
  //   //   "2wZHxuzklufvyf3Pl/UCy1vuqHWX4x74y2ISBiP3mDI=",
  //   //   SECRET_KEY,
  //   //   { iv: "6b94344135b92d7412d3679fce12bd52" }
  //   // ).toString(CryptoJS.enc.Utf8)
  // );
  const add_document = () => {
    if (!d_data.name || !d_data.img) {
      infoMess("Please fill all fields");
      return;
    }
    if (
      !(d_data.type.value == "Aadhar" || d_data.type.value == "PAN") &&
      !d_data.date
    ) {
      infoMess("Please fill all fields");
      return;
    }
    value != 0 && doc_status();
    const body = new FormData();
    body.append("action", "create");
    body.append("userid", ID);
    body.append("doctype", d_data.type.value);
    body.append("docname", d_data.name);
    body.append("docfile", d_data.img);
    d_data.type == "Aadhar" ||
      d_data.type == "PAN" ||
      body.append("expiry", d_data.date);
    addUserDocument(body, setLoading).then(
      (v) => v.success && navigate("/", { replace: true })
    );
  };

  const options = [
    { value: "Aadhar", label: "Aadhar" },
    { value: "PAN", label: "PAN" },
    { value: "RC", label: "RC" },
    { value: "Driving license", label: "Driving license" },
    { value: "Passport", label: "Passport" },
  ];

  const doc_status = (val) => {
    if (!d_data.password && value === 1) {
      setD_data({
        ...d_data,
        passwordval: true,
        passwordmess: "password is required",
      });
      return;
    }
    const body = new FormData();
    body.append("action", "update_docpwd");
    body.append("id", ID);
    body.append("status", value);
    value === 1 && body.append("password", encryptData(d_data.password));
    doc_status_action(body).then((v) => {
      if (v.success) {
        setD_data({ ...d_data, password: "" });
        onClose();
        toast.success(v.message);
        // setValue(d_status);
      } else {
        toast.error(v.message);
      }
    });
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        add_document();
      }
    },
    [d_data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <HStack display={"flex"} justifyContent={"space-between"}>
        <BreadCrumName
          current={"Add Document"}
          titlelist={[{ name: "Home", to: "/" }]}
        />
        <CustomButton1
          icon={<BiArrowBack />}
          title={"Back"}
          onClick={() => navigate(-1)}
        />
      </HStack>
      <Container
        w={"full"}
        maxW={"md"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack flexDirection={"column"} maxW={"md"} alignSelf={"center"}>
          <Text mb={0}>Select document type*</Text>
          <Select
            value={d_data.type}
            placeholder={"Search and select document"}
            onChange={(v) => setD_data({ ...d_data, type: v })}
            options={options}
          />

          {d_data?.type && (
            <Stack>
              <CustomInput
                placeholder={`Enter ${d_data.type.value} name`}
                label={`${d_data.type.value} name*`}
                value={d_data.name}
                onChange={(v) => setD_data({ ...d_data, name: v.target.value })}
              />

              <SelectImage
                label={"Upload file*"}
                mt={"10px"}
                filename={d_data?.img?.name}
                type={d_data?.img?.type}
                url={d_data?.img?.type && path}
                onChange={(e) => {
                  setPath(window.URL.createObjectURL(e.target.files[0]));
                  setD_data({ ...d_data, img: e.target.files[0] });
                }}
              />
              {d_data.type.value == "Aadhar" || d_data.type.value == "PAN" || (
                <CustomInput
                  placeholder={"Enter Expiry Date"}
                  label={"Expiry Date*"}
                  type={"date"}
                  value={d_data.date}
                  min={"1900-01-01"}
                  max="9999-12-31"
                  onChange={(v) =>
                    setD_data({
                      ...d_data,
                      date: v.target.value,
                    })
                  }
                />
              )}
              {user_data.docpwd_status == 0 && (
                <Stack>
                  <Text>Would you like to password protect the document?</Text>
                  <Flex gap={7}>
                    <Flex
                      alignItems={"center"}
                      gap={2}
                      cursor={"pointer"}
                      onClick={onOpen}
                    >
                      <Box
                        h={4}
                        w={4}
                        borderRadius={"full"}
                        bg={value === 1 ? "#FFBF00" : "gray.400"}
                      ></Box>
                      <Text fontWeight={"semibold"}>Yes</Text>
                    </Flex>
                    <Flex
                      alignItems={"center"}
                      gap={2}
                      cursor={"pointer"}
                      onClick={() => {
                        // doc_status(2);
                        setValue(2);
                      }}
                    >
                      <Box
                        h={4}
                        w={4}
                        borderRadius={"full"}
                        bg={value === 2 ? "#FFBF00" : "gray.400"}
                      ></Box>
                      <Text fontWeight={"semibold"}>No</Text>
                    </Flex>
                  </Flex>
                </Stack>
              )}
              <Center mt={4}>
                <CustomButton1
                  title={"Submit"}
                  onClick={add_document}
                  loading={loading}
                />
              </Center>
            </Stack>
          )}
        </Stack>
      </Container>
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Protect document"}
        mess={
          <>
            <CustomInput
              label={"Password"}
              placeholder={"Enter password"}
              value={decryptData(d_data.password)}
              // value={decryptData(d_data.password)}
              onChange={(e) => {
                setD_data({
                  ...d_data,
                  password: encryptData(e.target.value),
                  passwordmess: "",
                  passwordval: false,
                });
              }}
              error={d_data.passwordmess}
              type={"password"}
            />
          </>
        }
        b_name={"Submit"}
        b_color={"#FFBF00"}
        onClick={() => {
          // doc_status(1);
          setValue(1);
          onClose();
        }}
      />
    </Container>
  );
};

export default Adddocument;
