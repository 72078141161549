import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomButton1 from "../../components/CustomButton1";
import { FiTrash2 } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import store from "../../redux/Store";
import { getDocument_list } from "../../redux/slice/DocumentSlice";
import CustomPopup from "../../components/CustomPopup";
import {  decryptData, downloadImage, encryptData } from "../../utils/utils";
import { addUserDocument, doc_verify_action } from "../../utils/apis";
import Loader from "../../components/Loader";
import { IoMdMore } from "react-icons/io";
import { FaFileDownload } from "react-icons/fa";
import CustomInput from "../../components/CustomInput";
import { toast } from "react-toastify";
import ImageModal from "../../components/ImageModal";
import aadharImg from "../../images/Aadhaar.png";
import panImg from "../../images/pan_card.png";
import rcImg from "../../images/RC.png";
import passportImg from "../../images/passport.png";
import licenseImg from "../../images/license.png";
import ImgComp from "../../components/ImgComp";
import moment from "moment";
import { getUserData } from "../../redux/slice/userSlice";

const Documents = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;

  const navigate = useNavigate();
  const inputRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [password, setPassword] = useState({});

  const {
    isOpen: isVOpen,
    onOpen: onVOpen,
    onClose: onVClose,
  } = useDisclosure();
  const {
    isOpen: isIOpen,
    onOpen: onIOpen,
    onClose: onIClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [id, setId] = useState("");
  const { document_list, d_loading } = useSelector((state) => state.document);
  const { user_data, user_id } = useSelector((state) => state.user);
  const [delkey, setDelkey] = useState(false);

  useEffect(() => {
    store.dispatch(getUserData(user_id));
    localStorage.getItem("user");
    store.dispatch(getDocument_list(ID));
  }, [user_id]);

  const delete_doc = () => {
    onClose();
    const body = new FormData();
    body.append("action", "delete");
    body.append("userid", ID);
    body.append("id", id);
    addUserDocument(body, setLoading).then(
      (v) => v.success && store.dispatch(getDocument_list(ID))
    );
  };

  const doc_status = () => {
    if (!password.value) {
      setPassword({
        ...password,
        mess: "Password is required",
      });
      return;
    }
    const body = new FormData();
    body.append("action", "verifypwd");
    body.append("id", id);
    body.append("userid", ID);
    body.append("password", encryptData(password.value)); //encrypted password
    setLoading(true);
    doc_verify_action(body).then((v) => {
      if (v.success) {
        store.dispatch(getDocument_list(ID));
        setPassword({ ...password, value: "" });
        onVClose();
        onIOpen();
        setUrl(v.message);
        setLoading(false);
      } else {
        toast.error(v.message);
        setLoading(false);
      }
    });
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        delkey ? delete_doc() : doc_status();
      }
    },
    [password, id]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  function daysRemaining(date) {
    var eventdate = moment(date);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }
  return (
    <Container maxW={"full"}>
      <Flex align={"center"} justify={"space-between"}>
        <Heading
          fontSize={["md", "lg", "xl", "2xl"]}
          fontWeight={"medium"}
          color={"#0f0d66"}
        >
          Documents
        </Heading>
        <CustomButton1
          title={"Add Documents"}
          onClick={() => navigate("/add-document")}
        />
      </Flex>
      {d_loading && document_list.length != 0 ? (
        <Loader />
      ) : document_list.length == 0 ? (
        <>
          <Text
            fontSize={["12px", "14px", "16px"]}
            color={"#000"}
            fontWeight={"normal"}
            marginTop={2}
          >
            Let's add your first document
          </Text>
          <Flex flexWrap={"wrap"} display={"flex"} flexDirection={"row"}>
            <ImgComp
              img={aadharImg}
              title={"Aadhaar"}
              onClick={() => navigate("/add-document")}
            />
            <ImgComp
              img={panImg}
              title={"Pan"}
              onClick={() => navigate("/add-document")}
            />
            <ImgComp
              img={rcImg}
              title={"RC"}
              onClick={() => navigate("/add-document")}
            />
            <ImgComp
              img={licenseImg}
              title={"Driving License"}
              onClick={() => navigate("/add-document")}
            />
            <ImgComp
              img={passportImg}
              title={"Passport"}
              onClick={() => navigate("/add-document")}
            />
          </Flex>
        </>
      ) : (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={5} mt={3}>
          {document_list.map((v, i) => {
            return (
              <Card p={5} key={i}>
                <Flex align={"center"} justify={"space-between"}>
                  <Text
                    fontSize={["sm", "md", "xl", "lg"]}
                    fontWeight={"medium"}
                    textAlign={"center"}
                  >
                    {v.doc_type}
                  </Text>
                  <Popover placement="bottom-end">
                    <PopoverTrigger>
                      <Button
                        borderRadius={"full"}
                        bg={"transparent"}
                        px={0}
                        py={0}
                        m={0}
                        _hover={{ bg: "transparent" }}
                      >
                        <IoMdMore size={25} />
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent w={"auto"} h={"auto"}>
                        <PopoverArrow />
                        <PopoverBody>
                          <CustomLink
                            icon={<BiEditAlt size={18} color="blue" />}
                            title={"Edit"}
                            onClick={() =>
                              navigate("/edit-document", { state: v })
                            }
                          />
                          <CustomLink
                            icon={<FiTrash2 size={18} color="#f00" />}
                            title={"Delete"}
                            onClick={() => {
                              setId(v.id);
                              setDelkey(true);
                              onOpen();
                            }}
                          />

                          {user_data?.docpwd_status == 1 ||
                            (v?.document?.split(".")?.pop()?.trim() == "pdf" ? (
                              <Link href={v?.document} download isExternal>
                                <CustomLink
                                  icon={
                                    <FaFileDownload size={18} color="#000" />
                                  }
                                  title={"Download"}
                                />
                              </Link>
                            ) : (
                              <CustomLink
                                onClick={() => downloadImage(v?.document)}
                                icon={<FaFileDownload size={18} color="#000" />}
                                title={"Download"}
                              />
                            ))}
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
                <Text
                  fontSize={["xs", "sm", "md"]}
                  fontWeight={"medium"}
                  mb={0}
                >
                  Name: {v.Name}
                </Text>
                {Date.parse(v.expiry_date) ? (
                  <Flex gap={1} alignItems={"center"} mt={"5px"}>
                    <Text
                      fontSize={["xs", "sm", "md"]}
                      fontWeight={"medium"}
                      mb={0}
                    >
                      Expiry Date:
                    </Text>
                    <Text
                      fontSize={["xs", "sm", "md"]}
                      fontWeight={"medium"}
                      mb={0}
                      px={2}
                      borderRadius={3}
                      color={
                        daysRemaining(v.expiry_date) < 7
                          ? "#fff"
                          : daysRemaining(v.expiry_date) < 15
                          ? "#fff"
                          : daysRemaining(v.expiry_date) < 30
                          ? "#fff"
                          : "#000"
                      }
                      bg={
                        daysRemaining(v.expiry_date) < 7
                          ? "#f00"
                          : daysRemaining(v.expiry_date) < 15
                          ? "#FFCC00"
                          : daysRemaining(v.expiry_date) < 30
                          ? "#A9A9A9"
                          : "#fff"
                      }
                    >
                      {moment(v.expiry_date).format("DD/MM/YYYY")}
                    </Text>
                  </Flex>
                ) : (
                  ""
                )}
                {/* <Text
                  fontSize={["xs", "sm", "md"]}
                  fontWeight={"medium"}
                  mb={0}
                >
                  {Date.parse(v.expiry_date)
                    ? "Expiry Date:  " +
                      moment(v.expiry_date).format("DD/MM/YYYY")
                    : ""}
                </Text> */}
                <Text
                  mt={2}
                  cursor={"pointer"}
                  alignSelf={"end"}
                  fontWeight={"semibold"}
                  color={"#FFBF00"}
                  onClick={() => {
                    setDelkey(false);
                    (user_data.docpwd_status == 0 ||
                      user_data.docpwd_status == 2) &&
                      setUrl(v.document);
                    (user_data.docpwd_status == 0 ||
                      user_data.docpwd_status == 2) &&
                      onIOpen();
                    user_data.docpwd_status == 1 && setId(v.id);
                    user_data.docpwd_status == 1 && onVOpen();
                  }}
                >
                  View
                </Text>
              </Card>
            );
          })}
        </SimpleGrid>
      )}
      <CustomPopup
        isOpen={isOpen}
        onClick={() => delete_doc()}
        onClose={onClose}
        b_name={"Delete"}
        title={"Delete"}
        mess={"Are you sure? you want to delete document"}
      />
      <CustomPopup
        isOpen={isVOpen}
        onClose={onVClose}
        title={"Document Password"}
        mess={
          <>
            <CustomInput
              initialRef={inputRef}
              label={"Password"}
              placeholder={"Enter password"}
              value={password.value}
              onChange={(e) =>
                setPassword({ ...password, value: e.target.value, mess: "" })
              }
              error={password.mess}
              type={"password"}
            />
          </>
        }
        loading={loading}
        b_name={"Submit"}
        b_color={"#FFBF00"}
        onClick={doc_status}
      />
      <ImageModal isOpen={isIOpen} onClose={onIClose} url={decryptData(url)} />
    </Container>
  );
};

const CustomLink = ({ icon, title, onClick }) => {
  return (
    <Flex gap={2} align={"center"} cursor={"pointer"} onClick={onClick} mt={2}>
      {icon}
      <Text fontSize={"sm"} fontWeight={"medium"}>
        {title}
      </Text>
    </Flex>
  );
};

export default Documents;
