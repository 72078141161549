import {
  Card,
  Center,
  Container,
  Flex,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import CustomButton1 from "../components/CustomButton1";
import {
  emailRegex,
  encryptData,
  errorMess,
  infoMess,
  passwordRegex,
  successMess,
} from "../utils/utils";
import { base_url, register, send_otp } from "../utils/apis";
import CustomModal from "../components/CustomModal";
import parse from "html-react-parser";
import store from "../redux/Store";
import { getUserData } from "../redux/slice/userSlice";

const Sigup = () => {
  const [checked, setChecked] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const [terms, setTerms] = useState(false);
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [name, setName] = useState({ value: "", validate: false, mess: "" });
  const [mobile, setMobile] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [email, setEmail] = useState({ value: "", validate: false, mess: "" });
  const [c_password, setC_password] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);
  const [show_otp, setShow_otp] = useState(false);
  const [otp, setOtp] = useState("");
  const [id, setId] = useState("");
  const [latlon, setLatlon] = useState({});
  const [termData, setTermData] = useState("");

  const handleClick = () => setShow(!show);
  const handleClick1 = () => setShow1(!show1);

  const getMyLocation = async () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLatlon({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {},
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    }
  };

  const validate = () => {
    if (
      name.value.length < 3 &&
      email.value.length < 3 &&
      password.value.length < 3 &&
      c_password.value.length < 3 &&
      mobile.value.length < 3
    ) {
      setPassword({
        ...password,
        validate: true,
        mess: "Password is required",
      });

      setEmail({
        ...email,
        validate: true,
        mess: "Email address is required",
      });
      setMobile({
        ...mobile,
        validate: true,
        mess: "Mobile Number is required",
      });
      setC_password({
        ...c_password,
        validate: true,
        mess: "Confirm password is required",
      });
      setName({
        ...name,
        validate: true,
        mess: "Name is required",
      });
      return;
    }
    if (name.value.length < 3) {
      setName({
        ...name,
        validate: true,
        mess: "Name is required",
      });
      return;
    }
    if (mobile.value.length != 10) {
      setMobile({
        ...mobile,
        validate: true,
        mess: "Please enter valid mobile number",
      });
      return;
    }
    if (!emailRegex.test(email.value)) {
      setEmail({
        ...email,
        validate: true,
        mess: "invalid Email address",
      });
      return;
    }

    if (!passwordRegex.test(password.value)) {
      setPassword({
        ...password,
        validate: true,
        mess: "Password must be at least 8 characters long and contain at least  one special character!",
      });
      return;
    }

    if (password.value !== c_password.value) {
      setC_password({
        ...c_password,
        validate: true,
        mess: "Confirm password did not match password",
      });
      return;
    }
    if (!otp) {
      infoMess("OTP is required, Please enter OTP");
      return;
    }
    if (!latlon.latitude || !latlon.longitude) {
      infoMess("Location is required, Please turn on location");
      return;
    }
    if (
      name.value ||
      email.value ||
      passwordRegex.test(password.value) ||
      password.value === c_password.value ||
      mobile.value ||
      id ||
      otp
    ) {
      const body = new FormData();
      body.append("name", name.value);
      body.append("email", email.value);
      body.append("contact", mobile.value);
      body.append("id", id);
      body.append("otp", otp);
      body.append("latlong", latlon?.latitude + "," + latlon?.longitude);
      body.append("password", encryptData(password.value));
      body.append("platform", "Web");
      body.append("type", "signup");
      register(body, setLoading).then((v) => {
        if (v.success) {
          store.dispatch(getUserData(v.userid));
          localStorage.setItem("user", JSON.stringify(v));
          navigate("/");
        }
      });
    }
  };

  useEffect(() => {
    getMyLocation();
  }, []);
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        validate();
      }
    },
    [name, email, password, c_password, mobile, otp, id, latlon]
  );
  useEffect(() => {
    user !== null && navigate("/");
  }, []);
  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  const get_otp = () => {
    if (mobile.value.length < 10) {
      setMobile({
        ...mobile,
        validate: true,
        mess: "Please enter valid mobile number",
      });
      return;
    }
    send_otp(mobile.value, setLoading).then((v) => {
      if (v.success) {
        successMess(v.message);
        setId(v.id);
        setShow_otp(true);
      } else {
        errorMess(v.message);
        setShow_otp(false);
      }
    });
  };

  const getterms = async () => {
    try {
      const responce = await fetch(base_url + "Terms.php", {
        method: "GET",
      });
      const res = await responce.json();

      if (res.success) {
        setTermData(res.data);
      } else {
        setTermData("");
      }
    } catch (error) {}
  };
  useEffect(() => {
    getterms();
  }, []);

  return (
    <Container
      w={"full"}
      h={"100vh"}
      maxW={"full"}
      bg={"#084480"}
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      // bgImage={"bg.png"}
      background={"url(bg.png) center/cover no-repeat"}
    >
      <VStack
        h={"full"}
        w={"full"}
        justify={"center"}
        align={"center"}
        display={["none", "none", "none", "flex"]}
      ></VStack>
      <VStack h={"full"} w={"full"} justify={"center"} align={"center"}>
        <Card px={5} w={["full", "lg"]} justify={"center"} py={10}>
          <Center>
            <Image src="logo1.jpeg" h={"4rem"} w={"120px"} borderRadius={5} />
          </Center>
          <Text fontSize={"lg"} textAlign={"center"} mt={2} color={"#000"}>
            Sign Up
          </Text>

          <CustomInput
            placeholder="Name"
            value={name.value}
            onChange={(e) =>
              setName({
                ...name,
                value: e.target.value,
                validate: false,
                mess: "",
              })
            }
            isInvalid={name.validate}
            error={name.mess}
          />
          <CustomInput
            placeholder="Mobile Number"
            value={mobile.value}
            onChange={(e) => {
              e.target.value.length < 11 &&
                setMobile({
                  ...mobile,
                  value: e.target.value.replace(/\D/g, ""), // v.replace(/[^0-9]/g, ''),,
                  validate: false,
                  mess: "",
                });
            }}
            isInvalid={mobile.validate}
            error={mobile.mess}
            rightButton={
              <Center mt={1}>
                <CustomButton1
                  title={"Get OTP"}
                  loading={loading == 1 ? true : false}
                  onClick={() => get_otp()}
                />
              </Center>
            }
          />
          {show_otp && (
            <HStack justify={"center"} mt={3}>
              <PinInput placeholder="-" autoFocus value={otp} onChange={setOtp}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          )}
          <CustomInput
            placeholder="Email ID"
            value={email.value}
            onChange={(e) =>
              setEmail({
                ...email,
                value: e.target.value,
                validate: false,
                mess: "",
              })
            }
            isInvalid={email.validate}
            error={email.mess}
          />

          <CustomInput
            placeholder="Password"
            type={show ? "text" : "password"}
            value={password.value}
            onChange={(e) =>
              setPassword({
                ...password,
                value: e.target.value,
                validate: false,
                mess: "",
              })
            }
            isInvalid={password.validate}
            error={password.mess}
            right={
              !show ? (
                <FiEye cursor={"pointer"} size={18} onClick={handleClick} />
              ) : (
                <FiEyeOff cursor={"pointer"} size={18} onClick={handleClick} />
              )
            }
          />
          <CustomInput
            placeholder="Confirm Password"
            type={show1 ? "text" : "password"}
            value={c_password.value}
            onChange={(e) =>
              setC_password({
                ...c_password,
                value: e.target.value,
                validate: false,
                mess: "",
              })
            }
            isInvalid={c_password.validate}
            error={c_password.mess}
            right={
              !show1 ? (
                <FiEye cursor={"pointer"} size={18} onClick={handleClick1} />
              ) : (
                <FiEyeOff cursor={"pointer"} size={18} onClick={handleClick1} />
              )
            }
          />
          <Flex gap={1} paddingBlock={2} alignItems={"center"}>
            {/* <Checkbox
              colorScheme="yellow"
              isChecked={checked}
              onChange={() => setChecked(!checked)}
            />
             */}
            {/* I agree to */}
            <Text fontSize={"15px"} color={"#000"}>
              By clicking Sign Up, you agree to our
            </Text>
            <Text
              fontSize={"16px"}
              color={"#385898"}
              cursor={"pointer"}
              onClick={() => setTerms(true)}
            >
              Terms and Condition
            </Text>
          </Flex>
          <Center mt={4}>
            <CustomButton1
              title={"Sign Up"}
              loading={loading == 5 ? true : false}
              onClick={() => validate()}
            />
          </Center>
          <Link
            to={"/login"}
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: "16px",
              color: "#FFBF00",
              alignSelf: "center",
            }}
          >
            Already have an account?
          </Link>
        </Card>
      </VStack>
      <CustomModal
        isOpen={terms}
        onClose={() => setTerms(false)}
        body={
          <>
            <Text
              fontSize={"xl"}
              color={"#385898"}
              fontWeight={"medium"}
              textAlign={"center"}
            >
              Terms and condition
            </Text>
            <Spacer h={2} />
            <Text
              fontSize={"md"}
              color={"#000"}
              // fontWeight={"medium"}
            >
              {parse(termData)}
            </Text>
          </>
        }
      />
    </Container>
  );
};

export default Sigup;
