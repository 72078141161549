import { Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

const ImgComp = ({ img, title, onClick }) => {
  return (
    <Stack
      padding={["10px", 2, 5]}
      //   border={"1px solid red"}
      m={["5px", 2, 5]}
      borderRadius={5}
      shadow={"xs"}
      backgroundColor={"#fff"}
      cursor={"pointer"}
      onClick={onClick}
    >
      <Image
        src={img}
        w={["40px", "70px", "100px", "140px"]}
        h={["40px", "70px", "100px", "140px"]}
        objectFit={"contain"}
      />
      <Text
        fontSize={["12px", "14px", "16px"]}
        textAlign={"center"}
        color={"#000"}
        fontWeight={"normal"}
        marginTop={2}
      >
        {title}
      </Text>
    </Stack>
  );
};

export default ImgComp;
