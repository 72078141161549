import {
  Center,
  Container,
  Image,
  HStack,
  Heading,
  PinInput,
  PinInputField,
  Stack,
  Text,
  Link,
  VStack,
  Card,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../components/CustomInput";
import CustomButton1 from "../components/CustomButton1";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  emailRegex,
  encryptData,
  errorMess,
  infoMess,
  passwordRegex,
  successMess,
} from "../utils/utils";
import { forgot_pass, reset_otp } from "../utils/apis";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const inputRef = useRef();
  const inputRef1 = useRef();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    show_otp: false,
  });
  const handleClick = () => {
    inputRef.current.focus();
    setShow(!show);
  };
  const handleClick1 = () => {
    inputRef1.current.focus();
    setShow1(!show1);
  };
  const sendOtp = () => {
    if (!data.contact) {
      //   infoMess("Please enter your email / mobile number");
      setData({
        ...data,
        con_mess: "Please enter your email/mobile number",
        con_validate: true,
      });
      return;
    }
    if (data.contact.includes("@")) {
      if (!emailRegex.test(data.contact)) {
        setData({
          ...data,
          con_mess: "Please enter valid email address",
          con_validate: true,
        });
        return;
      }
    }
    if (!data.contact.includes("@")) {
      if (data.contact.replace(/\D/g, "").length < 10) {
        setData({
          ...data,
          con_mess: "Please enter valid email/mobile number",
          con_validate: true,
        });
        return;
      }
    }
    // if (data.contact.length < 5) {
    //   //   infoMess("Please enter your email / mobile number");
    //   setData({
    //     ...data,
    //     con_mess: "Please enter valid email/mobile number",
    //     con_validate: true,
    //   });
    //   return;
    // }
    reset_otp(data.contact, data.contact, setLoading).then((v) => {
      if (v.success) {
        successMess(v.message);
        setData({ ...data, id: v.id, show_otp: true });
      } else {
        errorMess(v.message);
        setData({ ...data, show_otp: false });
      }
    });
  };

  const onSubmit = () => {
    if (!data.contact) {
      //   infoMess("Please enter your email / mobile number");
      setData({
        ...data,
        con_mess: "Please enter your email/mobile number",
        con_validate: true,
      });
      return;
    }
    if (!data.otp) {
      infoMess("Please enter your otp");
      return;
    }
    if (!passwordRegex.test(data.value)) {
      setData({
        ...data,
        validate: true,
        mess: "Password must be at least 8 characters long and contain at least  one special character !",
      });
      return;
    }

    if (data.value !== data.value1) {
      setData({
        ...data,
        validate1: true,
        mess1: "Confirm password did not match password",
      });
      return;
    }
    const body = new FormData();
    body.append("id", data.id);
    body.append("otp", data.otp);
    body.append("contact", data.contact);
    body.append("password", encryptData(data.value)); //encrypted data
    body.append("action", "resetpassword");

    forgot_pass(body, setLoading).then((v) => {
      if (v.success) {
        successMess(v.message);
        navigate(-1);
      } else {
        errorMess(v.message);
      }
    });
  };
  useEffect(() => {
    user !== null && navigate("/");
  }, []);
  return (
    <Container
      w={"full"}
      h={"100vh"}
      maxW={"full"}
      bg={"#084480"}
      justifyContent={"center"}
      alignItems={"center"}
      display={["flex", "flex", "flex", "flex"]}
      // bgImage={"bg.png"}
      background={"url(bg.png) center/cover no-repeat"}
    >
      <VStack
        h={"full"}
        w={"50%"}
        justify={"center"}
        align={"center"}
        display={["none", "none", "flex", "flex"]}
      >
        {/* <Image
          src={"login-img.png"}
          maxW={["xs", "xs", "xs", "sm"]}
          maxH={["xs", "xs", "xs", "sm"]}
          h={"full"}
          w={"full"}
          objectFit={"contain"}
        />
        <Heading color={"white"}>Enjoy risk-free life</Heading>
        <Text color={"white"} fontSize={"xl"}>
          at every stage, Let Insurance cover them.
        </Text> */}
      </VStack>
      <VStack
        h={"full"}
        w={["full", "70%", "50%"]}
        justify={"center"}
        align={"center"}
      >
        <Card
          px={5}
          size={"lg"}
          w={"full"}
          maxW={"md"}
          justify={"center"}
          py={5}
        >
          <Center>
            <Image
              src="logo1.jpeg"
              h={"4rem"}
              w={"120px"}
              // bg={"gray"}
              borderRadius={5}
            />
          </Center>
          <Text fontSize={"lg"} textAlign={"center"} mt={2}>
            Reset Password
          </Text>
          <CustomInput
            label={"Email / Mobile Number"}
            placeholder={"Enter Email / Mobile Number"}
            value={data.contact}
            onChange={(e) =>
              setData({
                ...data,
                contact: e.target.value,
                con_mess: "",
                con_validate: false,
              })
            }
            isInvalid={data.con_validate}
            error={data.con_mess}
            rightButton={
              <Stack mt={1}>
                <CustomButton1
                  title={"Send OTP"}
                  loading={loading == 2 ? true : false}
                  onClick={sendOtp}
                />
              </Stack>
            }
          />

          {data.show_otp && (
            <>
              <HStack justify={"center"} mt={3}>
                <PinInput
                  placeholder="-"
                  autoFocus
                  value={data.otp}
                  onChange={(e) => setData({ ...data, otp: e })}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <CustomInput
                disabled={!data.show_otp}
                initialRef={inputRef}
                label={"New Password"}
                placeholder="Enter New Password"
                type={show ? "text" : "password"}
                value={data.value}
                onChange={(e) => {
                  e.preventDefault();
                  setData({
                    ...data,
                    value: e.target.value,
                    mess: "",
                    validate: false,
                  });
                }}
                isInvalid={data.validate}
                error={data.mess}
                right={
                  !show ? (
                    <FiEye cursor={"pointer"} size={18} onClick={handleClick} />
                  ) : (
                    <FiEyeOff
                      cursor={"pointer"}
                      size={18}
                      onClick={handleClick}
                    />
                  )
                }
              />
              <CustomInput
                disabled={!data.show_otp}
                initialRef={inputRef1}
                label={"Confirm Password"}
                placeholder="Enter Confirm Password"
                type={show1 ? "text" : "password"}
                value={data.value1}
                onChange={(e) => {
                  e.preventDefault();
                  setData({
                    ...data,
                    value1: e.target.value,
                    mess1: "",
                    validate1: false,
                  });
                }}
                isInvalid={data.validate1}
                error={data.mess1}
                right={
                  !show1 ? (
                    <FiEye
                      cursor={"pointer"}
                      size={18}
                      onClick={handleClick1}
                    />
                  ) : (
                    <FiEyeOff
                      cursor={"pointer"}
                      size={18}
                      onClick={handleClick1}
                    />
                  )
                }
              />
              <Center mt={4}>
                <CustomButton1
                  title={"Submit"}
                  onClick={onSubmit}
                  loading={loading == "for" ? true : false}
                />
              </Center>
            </>
          )}

          <Link
            // to={"/login"}
            onClick={() => navigate(-1)}
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: "16px",
              color: "#FFBF00",
              alignSelf: "center",
            }}
          >
            Login?
          </Link>
        </Card>
      </VStack>
    </Container>
  );
};

export default Login;
