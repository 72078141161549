import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
export const getNotifications = createAsyncThunk("notification", async (id) => {
  try {
    const body = new FormData();
    body.append("customerid", id);
    body.append("type", "user");
    const responce = await fetch(base_url + "notificationlist.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();

    return res;
  } catch (error) {
    console.log(error);
  }
});

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notification_list: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      if (data.success) {
        state.notification_list =
          data.data == "No data Avaialable" ? [] : data.data;
      } else {
        state.user_data = [];
      }
    });
  },
});
export default notificationSlice.reducer;
