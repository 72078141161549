import { Button } from "@chakra-ui/react";
import React from "react";

const CustomButton1 = ({
  title,
  loading,
  onClick,
  mt,
  bg,
  h,
  icon,
  id,
  t_color,
  disabled,
}) => {
  return (
    <Button
      type="button"
      id={id}
      variant={"solid"}
      h={h || ["8", 30]}
      mt={mt}
      bg={bg || "#FFBF00"}
      borderWidth={1}
      _hover={{ bg: `${bg}+77` || "#FFBF0099" }}
      color={t_color || "#fff"}
      isLoading={loading}
      onClick={onClick}
      alignSelf={"flex-start"}
      gap={2}
      fontSize={["sm", "sm", "md"]}
      disabled={disabled}
    >
      {icon}
      {title}
    </Button>
  );
};

export default CustomButton1;
