import {
  Center,
  Container,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import CustomInput from "../../components/CustomInput";
import CustomButton1 from "../../components/CustomButton1";
import { useSelector } from "react-redux";
import { addQute } from "../../utils/apis";
import {  emailRegex, infoMess } from "../../utils/utils";

const AskanExpert = () => {
  const { user_data } = useSelector((state) => state.user);
  const [ask_data, setAsk_data] = useState({});
  const [tab, setTab] = useState("Health");
  const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const sendQuote = () => {
    if (
      !ask_data.Name ||
      !ask_data.Email ||
      !ask_data.mobile ||
      !ask_data.mess
    ) {
      infoMess("all fields are required");
      return;
    }

    if (!emailRegex.test(ask_data.Email)) {
      infoMess("Invalid email address");
      return;
    }
    if (ask_data.mobile.length < 10) {
      infoMess("Invalid mobile number");
      return;
    }
    const body = new FormData();
    body.append("userid", ID);
    body.append("instype", tab);
    body.append("name", ask_data.Name);
    body.append("email", ask_data.Email);
    body.append("contact", ask_data.mobile);
    body.append("message", ask_data.mess);
    body.append("policy", "expert");
    addQute(body, setLoading).then((v) => v.success);
  };
  useEffect(() => {
    setAsk_data(user_data);
  }, [user_data]);

  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Ask an Expert"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.md"}>
        <RadioGroup onChange={setTab} value={tab} defaultValue="Health">
          <Stack direction="row" spacing={5}>
            <Radio value="Health">Health</Radio>
            <Radio value="Motor">Motor</Radio>
            <Radio value="Life">Life</Radio>
            <Radio value="Others">Others</Radio>
          </Stack>
        </RadioGroup>

        <CustomInput
          label={"Full Name*"}
          value={ask_data.Name}
          onChange={(e) => setAsk_data({ ...ask_data, Name: e.target.value })}
          placeholder={"Enter Full Name"}
        />
        <CustomInput
          label={"Email Address*"}
          value={ask_data.Email}
          onChange={(e) => setAsk_data({ ...ask_data, Email: e.target.value })}
          placeholder={"Enter Email Address"}
        />
        <CustomInput
          label={"Phone Number*"}
          value={ask_data.mobile}
          onChange={(e) =>
            e.target.value.length < 11 &&
            setAsk_data({
              ...ask_data,
              mobile: e.target.value.replace(/([a-zA-Z ])/g, ""),
            })
          }
          placeholder={"Enter Mobile Number"}
        />
        <CustomInput
          label={"Additional Infromation"}
          // value={mess}
          value={ask_data.mess}
          // onChange={(e) => setMess(e.target.value)}
          onChange={(e) => setAsk_data({ ...ask_data, mess: e.target.value })}
          placeholder={"Enter Additional Infromation"}
          area
        />
        <Center mt={4}>
          <CustomButton1
            title={"Submit"}
            loading={loading}
            onClick={sendQuote}
          />
        </Center>
      </Container>
    </Container>
  );
};

export default AskanExpert;
