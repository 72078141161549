import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Container, Flex, Image, Text, VStack } from "@chakra-ui/react";
import BreadCrumName from "../components/BreadCrumName";
import store from "../redux/Store";
import { getNotifications } from "../redux/slice/notificationSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const Notificationlist = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const { notification_list } = useSelector((state) => state.notifications);
  useEffect(() => {
    store.dispatch(getNotifications(user.userid));
  }, []);
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Notifications"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container
        w={"full"}
        maxW={"container.md"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {notification_list.length === 0 ? (
          <Image src={"no-data1.png"} h={"xs"} w={"sm"} objectFit={"cover"} />
        ) : (
          notification_list.map((v, i) => (
            <Card
              key={i}
              mb={2}
              py={3}
              px={4}
              cursor={"pointer"}
              onClick={() => navigate("/notification-detail", { state: v })}
            >
              <Flex justify={"space-between"}>
                <Text
                  mb={0}
                  fontSize={"md"}
                  fontWeight={"semibold"}
                  color={"#000"}
                >
                  {v.title}
                </Text>
                <Text
                  mb={0}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  color={"#000"}
                >
                  {Date.parse(v.scheduledate)
                    ? moment(v.scheduledate).fromNow()
                    : moment(v.datetime).fromNow()}
                </Text>
              </Flex>
              <Text
                mb={0}
                fontSize={"sm"}
                fontWeight={"normal"}
                color={"#000"}
                noOfLines={1}
              >
                {v.message}
              </Text>
            </Card>
          ))
        )}
      </Container>
    </Container>
  );
};

export default Notificationlist;
