import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
export const getUserData = createAsyncThunk("user", async (id) => {
  try {
    const body = new FormData();
    body.append("id", user?.userid || id);
    body.append("action", "listbyid");
    const responce = await fetch(base_url + "profile.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    return res;
  } catch (error) {}
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    user_data: {},
    user_id: "",
    loading: false,
    sessionOut: false,
  },
  reducers: {
    setSessionOut(state, action) {
      state.sessionOut = action.payload;
    },
    setUser_id(state, action) {
      state.user_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      if (data.success) {
        state.user_data = data.message;
      } else {
        state.user_data = {};
      }
    });
  },
});

export const { setSessionOut, setUser_id } = userSlice.actions;
export default userSlice.reducer;
